<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("mission") }}</h1>
      <br>
      <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">{{ $t("retour") }}</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">{{ $t("retour") }}</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">{{ $t("retour") }}</vs-button>
      </div>
      </div>
      <div  v-if="activePrompt1"  class="clearfix">
          <div class="vx-row">

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("project") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="project"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="project"
                    class="w-full"
                >
                <vs-select-item :key="tem" :value="tem.id" :text="tem.name" v-for="tem in projects" /></vs-select>
          <span class="text-danger text-sm" v-show="errors.has('project')">{{  $t("project_error") }}</span>
          </div>

          <div v-if="user_role === 'admin'" class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("manager") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="manager"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="manager"
                    class="w-full"
            >
            <vs-select-item :key="temf" :value="temf.id" :text="temf.first_name" v-for="temf in managers" /></vs-select>
          <span v-if="user_role === 'admin'" class="text-danger text-sm" v-show="errors.has('manager')">{{  $t("manager_error") }}</span>
          </div>

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("poNumber") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="poNumber"
                  v-model="poNumber"
                  class="w-full"
            />
          <span class="text-danger text-sm" v-show="errors.has('poNumber')">{{  $t("poNumber_error") }}</span>
          </div>

          <div  class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("woReference") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="woReference"
                  v-model="woReference"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('woReference')">{{  $t("woReference_error") }}</span>
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
            <p>{{ $t("numberOfSite") }}<b style="color: #ff6141">*</b></p>
                <vs-input-number
                  :min="0"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="numberOfSite"
                  v-model="numberOfSite"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('numberOfSite')">{{  $t("numberOfSite_error") }}</span>
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("team") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                collapse-chips
                :closeOnSelect="false"
                v-validate="'required'"
                v-model="team"
                data-vv-validate-on="blur"
                autocomplete
                name="team"
                class="w-full"
            >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in teams" /></vs-select>
          <span class="text-danger text-sm" v-show="errors.has('team')">{{  $t("team_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">  
          <p>{{ $t("startDate") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker1" 
              @on-change="onFromChange" 
              v-validate="'required'"
              label-placeholder=" Date"
              v-model="startDate"
              name="startDate"
              class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('startDate')">{{  $t("startDate_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("endDate") }}<b style="color: #ff6141">*</b></p>
              <flat-pickr 
              v-validate="'required'"
              :config="configdateTimePicker2" 
              @on-change="onToChange"
              label-placeholder=" Date"
              v-model="endDate"
              name="endDate"
              class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('endDate')">{{  $t("endDate_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("tasks") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                multiple 
                collapse-chips
                v-validate="'required'"
                :closeOnSelect="false"
                v-model="tasks_missions"
                data-vv-validate-on="blur"
                autocomplete
                name="tasks_missions"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showTasks">{{  $t("Voir") }}</vs-button>
            <span class="text-danger text-sm" v-show="errors.has('tasks_missions')">{{  $t("tasks_missions_error") }}</span>
          </div>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("riskProfile_ad") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                multiple
                collapse-chips
                v-model="riskProfiles"
                autocomplete
                name="riskProfile"
                class="w-full"
            >
          <vs-select-item :key="item5" :value="item5.id" :text="item5.name" v-for="item5 in riskProfiles_list" /></vs-select>

          <!--           <span class="text-danger text-sm" v-show="errors.has('riskProfile')">{{$t("riskProfile_error")}}</span>
          -->
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

          <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                multiple 
                v-validate="'required'"
                collapse-chips
                :closeOnSelect="false"
                v-model="sites"
                data-vv-validate-on="blur"
                autocomplete
                name="site"
                class="w-full"
            >
            <vs-select-item :key="item3" :value="item3.id" :text="item3.name" v-for="item3 in sites_missions" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('site')">{{  $t("site_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

            <p>{{ $t("headOfMissions") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                multiple 
                v-validate="'required'"
                collapse-chips
                :closeOnSelect="false"
                v-model="headOfMissions"
                data-vv-validate-on="blur"
                autocomplete
                name="headOfMissions"
                class="w-full"
            >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.last_name" v-for="item4 in  headOfMissions_liste" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('headOfMissions')">{{  $t("headOfMissions_error") }}</span>

          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
          <p>{{ $t("comonTransport") }}<b style="color: #ff6141" >*</b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="comonTransport"
            autocomplete
            name="comonTransport"
            class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('comonTransport')">{{ $t("comonTransport_error") }}</span>
          </div>

          <div v-if="comonTransport" class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
          <label v-if="comonTransport" class="text-sm opacity-75">{{ $t("comonTransportDetails") }}<b style="color: #ff6141" ></b></label>
          <vs-textarea
          name="comonTransportDetails"
          v-validate="'required'"
          v-model="comonTransportDetails"
          rows="3"
          class="w-full" />
          <span v-if="comonTransport" class="text-danger text-sm" v-show="errors.has('comonTransportDetails')">{{$t("comonTransportDetails_error") }}</span>
          </div>

          <!-- <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
                <span><b style="color:orange">{{ name_file1_3 }}</b></span>
                <input v-validate="'required'" name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".kmz,.kml" >
                <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{  $t("name_file1_3") }}</span>
                <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
          </div> -->
          <vx-card class="mt-10" no-shadow card-border>
                  <div class="vx-row ">
                    <div class="vx-col w-full">
                      <div class="flex items-end px-3">
                        <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
                        <span class="font-medium text-lg leading-none">{{ $t("info_equipe") }}</span>
                      </div>
                      <vs-divider />
                    </div>
                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-full">
                    <p>{{ $t("question_modif_equipe") }}</p>
                      <vs-checkbox 
                          v-model="edit_team"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          autocomplete
                          name="edit_team"
                          class="w-full">
                    </vs-checkbox>
                    </div>
                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-full">
                    <p>{{ $t("question_ajout_equipe") }}</p>
                      <vs-checkbox 
                          v-model="add_team"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          autocomplete
                          name="add_team"
                          class="w-full">
                    </vs-checkbox>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("name") }}<b style="color: #ff6141"></b></p>
                      <vs-input
                      v-if="edit_team == true || add_team == true"
                        data-vv-validate-on="blur"
                        name="name"
                        v-model="name"
                        class="w-full"
                      />
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("teamLeader") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                      v-if="edit_team == true || add_team == true"
                        :closeOnSelect="false"
                        v-model="teamLeader"
                        data-vv-validate-on="blur"
                        autocomplete
                        name="teamLeader"
                        class="w-full"
                      >
                      <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("company") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                        v-if="edit_team == true || add_team == true"
                          :disabled="role_dis"
                          :closeOnSelect="false"
                          v-model="company"
                          autocomplete
                          name="company"
                          class="w-full">
                     <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p  v-if="edit_team == true || add_team == true">{{ $t("staffs") }}<b style="color: #ff6141" >*</b> </p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple
                          collapse-chips 
                          :closeOnSelect="false"
                          v-model="staff"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="staff"
                          class="w-full"
                      >
                    <vs-select-item :key="item5" :value="item5.id" :text="item5.fullName" v-for="item5 in staffs" /></vs-select>
          <!--                     <span class="text-danger text-sm" v-show="errors.has('staff')">{{  $t("staff") }}</span>
          -->                    
                    </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

                      <p v-if="edit_team == true || add_team == true">{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="task"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="task"
                          class="w-full"
                      >
                    <vs-select-item :key="item6" :value="item6.id" :text="item6.name" v-for="item6 in tasks" /></vs-select>
            <!--             <span class="text-danger text-sm" v-show="errors.has('task')">{{  $t("task") }}</span>
            -->        </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                      <p v-if="edit_team == true || add_team == true">{{ $t("vehicles") }}<b style="color: #ff6141" >*</b></p>
                          <vs-select
                            multiple 
                            collapse-chips
                            :closeOnSelect="false"
                            v-model="vehicle"
                            autocomplete
                            name="vehicle"
                            class="w-full"
                        >
                      <vs-select-item :key="item7" :value="item7.id" :text="item7.plateNumber" v-for="item7 in vehicles" /></vs-select>
          <!--                       <span class="text-danger text-sm" v-show="errors.has('vehicle')">{{  $t("vehicle") }}</span> 
          -->                    </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p v-if="edit_team == true || add_team == true">{{ $t("liftingEquipments") }}<b style="color: #ff6141" >*</b></p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="liftingEquipment"
                          autocomplete
                          name="liftingEquipment"
                          class="w-full"
                      >
                    <vs-select-item :key="item8" :value="item8.id" :text="item8.name" v-for="item8 in liftingEquipments" /></vs-select>
          <!--                   <span class="text-danger text-sm" v-show="errors.has('liftingEquipment')">{{  $t("liftingEquipment") }}</span>
          -->                    </div>
                  </div>
                
                  <div v-if="edit_team == true || add_team == true" class="flex flex-wrap justify-between mt-8 mb-3">
                    <vs-button  v-if="add_team ==true"   @click="submitData_team">{{ $t("Ajouter") }}</vs-button>
                    <vs-button  v-if="edit_team ==true"  @click="submitData_team">{{ $t("Modifier") }}</vs-button>
                    <vs-button color="warning"  @click="intialise">{{ $t("Annuler") }}</vs-button>
                  </div>
          </vx-card>


          </div>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Valider") }}</vs-button>
      </div>

      <div  v-if="activePrompt2"  class="clearfix">
          <div class="vx-row">

            <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("project") }}<b style="color: #ff6141"></b></p>
            <vs-select
              disabled
              v-validate="'required'"
              :closeOnSelect="false"
              v-model="project"
              data-vv-validate-on="blur"
              autocomplete
              name="project"
              class="w-full"
            >
            <vs-select-item :key="tem" :value="tem.id" :text="tem.name" v-for="tem in projects" /></vs-select>
            </div>

          <div v-if="user_role === 'admin'" class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("manager") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                disabled
                :closeOnSelect="false"
                v-model="manager"
                autocomplete
                name="manager"
                class="w-full"
            >
          <vs-select-item :key="temf" :value="temf.id" :text="temf.first_name" v-for="temf in managers" /></vs-select>
          </div>

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("poNumber") }}<b style="color: #ff6141"></b></p>
                <vs-input
                  disabled
                  data-vv-validate-on="blur"
                  name="poNumber"
                  v-model="poNumber"
                  class="w-full"
                />
          </div>

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("woReference") }}<b style="color: #ff6141"></b></p>
                <vs-input
                  disabled
                  data-vv-validate-on="blur"
                  name="woReference"
                  v-model="woReference"
                  class="w-full"
                />
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
            <p>{{ $t("numberOfSite") }}<b style="color: #ff6141"></b></p>
                <vs-input-number
                disabled
                  :min="0"
                  data-vv-validate-on="blur"
                  name="numberOfSite"
                  v-model="numberOfSite"
                  class="w-full"
                />
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
            <p>{{ $t("team") }}<b style="color: #ff6141"></b></p>
            <vs-select
                disabled
                collapse-chips
                :closeOnSelect="false"
                v-model="team"
                data-vv-validate-on="blur"
                autocomplete
                name="team"
                class="w-full"
            >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in teams" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('team')">{{  $t("team_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">  
          <p>{{ $t("startDate") }}<b style="color: #ff6141"></b></p>
            <flat-pickr 
              disabled
              :config="configdateTimePicker11" 
              @on-change="onFromChange" 
              label-placeholder=" Date"
              v-model="startDate"
              name="startDate"
              class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('startDate')">{{  $t("startDate_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("endDate") }}<b style="color: #ff6141"></b></p>
              <flat-pickr 
              disabled
              :config="configdateTimePicker22" 
              @on-change="onToChange"
              label-placeholder=" Date"
              v-model="endDate"
              name="endDate"
              class="w-full" />
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                disabled
                multiple 
                collapse-chips
                :closeOnSelect="false"
                v-model="tasks_missions"
                data-vv-validate-on="blur"
                autocomplete
                name="tasks_missions"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showTasks">{{ $t("Voir") }}</vs-button>
          </div>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("riskProfile_ad") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                disabled
                multiple
                collapse-chips
                v-model="riskProfiles"
                autocomplete
                name="riskProfile"
                class="w-full"
            >
          <vs-select-item :key="item5" :value="item5.id" :text="item5.name" v-for="item5 in riskProfiles_list" /></vs-select>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

          <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                disabled
                multiple 
                collapse-chips
                :closeOnSelect="false"
                v-model="sites"
                data-vv-validate-on="blur"
                autocomplete
                name="site"
                class="w-full"
            >
            <vs-select-item :key="item3" :value="item3.id" :text="item3.name" v-for="item3 in sites_missions" /></vs-select>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

            <p>{{ $t("headOfMissions") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                disabled
                multiple 
                collapse-chips
                :closeOnSelect="false"
                v-model="headOfMissions"
                data-vv-validate-on="blur"
                autocomplete
                name="headOfMissions"
                class="w-full"
            >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.last_name" v-for="item4 in headOfMissionsList" /></vs-select>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
          <p>{{ $t("comonTransport") }}<b style="color: #ff6141" ></b> </p>
            <vs-checkbox 
            disabled
            v-validate="'required'"
            v-model="comonTransport"
            autocomplete
            name="comonTransport"
            class="w-full">
            </vs-checkbox>
          </div>

          <div v-if="comonTransport" class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
          <label v-if="comonTransport" class="text-sm opacity-75">{{ $t("comonTransportDetails") }}<b style="color: #ff6141" >*</b></label>
          <vs-textarea
          disabled
          name="comonTransportDetails"
          v-model="comonTransportDetails"
          rows="3"
          class="w-full" />
          </div>
         <!--  <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
                <span><b>Fichier</b></span>
                <br>
                <span><b style="color:orange">{{ name_file1_3 }}</b></span>
                
                <vs-button @click="openImage" color="primary" type="filled" icon-pack="feather" icon="icon-eye">Voir</vs-button>
          </div> -->
          <vx-card class="mt-10" no-shadow card-border>
                  <div class="vx-row ">
                    <div class="vx-col w-full">
                      <div class="flex items-end px-3">
                        <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
                        <span class="font-medium text-lg leading-none">{{ $t("info_equipe") }}</span>
                      </div>
                      <vs-divider />
                    </div>
                    <div  class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p >{{ $t("name") }}<b style="color: #ff6141"></b></p>
                      <vs-input
                        disabled
                        data-vv-validate-on="blur"
                        name="name"
                        v-model="name"
                        class="w-full"
                      />
                    </div>
                    <div  class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p >{{ $t("teamLeader") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                        disabled
                        :closeOnSelect="false"
                        v-model="teamLeader"
                        data-vv-validate-on="blur"
                        autocomplete
                        name="teamLeader"
                        class="w-full"
                      >
                      <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
                    </div>
                    <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p >{{ $t("company") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                          disabled
                          :closeOnSelect="false"
                          v-model="company"
                          autocomplete
                          name="company"
                          class="w-full">
                     <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
                    </div>
                  <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">
                    
                    <p>{{ $t("staffs") }}<b style="color: #ff6141" ></b> </p>
                        <vs-select
                          disabled
                          multiple
                          collapse-chips 
                          :closeOnSelect="false"
                          v-model="staff"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="staff"
                          class="w-full"
                      >
                    <vs-select-item :key="item5" :value="item5.id" :text="item5.fullName" v-for="item5 in staffs" /></vs-select>
                
                    </div>

                    <div class="vx-col sm:w-full md:w-1/2 lg:1/2 xs:w-1/2">

                      <p>{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
                        <vs-select
                          disabled
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="task"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="task"
                          class="w-full"
                      >
                    <vs-select-item :key="item6" :value="item6.id" :text="item6.name" v-for="item6 in tasks" /></vs-select>
                  </div>

                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                      <p>{{ $t("vehicles") }}<b style="color: #ff6141" ></b></p>
                          <vs-select
                            disabled
                            multiple 
                            collapse-chips
                            :closeOnSelect="false"
                            v-model="vehicle"
                            autocomplete
                            name="vehicle"
                            class="w-full"
                        >
                      <vs-select-item :key="item7" :value="item7.id" :text="item7.plateNumber" v-for="item7 in vehicles" /></vs-select>
                  </div>

                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p>{{ $t("liftingEquipments") }}<b style="color: #ff6141" ></b></p>
                        <vs-select
                          disabled
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="liftingEquipment"
                          autocomplete
                          name="liftingEquipment"
                          class="w-full"
                      >
                    <vs-select-item :key="item8" :value="item8.id" :text="item8.name" v-for="item8 in liftingEquipments" /></vs-select>
                    </div>
                  </div>
                
            
          </vx-card>

          </div>
          <vs-button color="primary"  class="float-right mt-4" @click="quit_mission()" >Quitter</vs-button>
      </div>
       <!-- modification -->
      <div  v-if="activePrompt3"  class="clearfix">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("project") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="project"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="project"
                    class="w-full"
                >
            <vs-select-item :key="tem" :value="tem.id" :text="tem.name" v-for="tem in projects" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('project')">{{  $t("project_error") }}</span>
          </div>

          <div v-if="user_role === 'admin'" class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("manager") }}<b style="color: #ff6141">*</b></p>
            <vs-select
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="manager"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="manager"
                    class="w-full"
            >
            <vs-select-item :key="temf" :value="temf.id" :text="temf.first_name" v-for="temf in managers" /></vs-select>
          <span v-if="user_role === 'admin'" class="text-danger text-sm" v-show="errors.has('manager')">{{  $t("manager_error") }}</span>
          </div>

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("poNumber") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="poNumber"
                  v-model="poNumber"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('poNumber')">{{  $t("poNumber_error") }}</span>
          </div>

          <div class="vx-col md:w-1/4 sm:w-1/4 lg:w-1/4 xs:w-1/4">
            <p>{{ $t("woReference") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="woReference"
                  v-model="woReference"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('woReference')">{{  $t("woReference_error") }}</span>
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("numberOfSite") }}<b style="color: #ff6141">*</b></p>
                <vs-input-number
                  :min="0"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="numberOfSite"
                  v-model="numberOfSite"
                  class="w-full"
                />
          <span class="text-danger text-sm" v-show="errors.has('numberOfSite')">{{  $t("numberOfSite_error") }}</span>
          </div>

          <div class="vx-col md:w-1/2 sm:w-1/2 lg:1/2 xs:w-1/2">
                <p>{{ $t("team") }}<b style="color: #ff6141">*</b></p>
                <vs-select
                  @click="remove_chip(team)"
                    collapse-chips
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="team"
                    data-vv-validate-on="blur"
                    autocomplete
                    name="team"
                    class="w-full"
                >
                <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in teams" /></vs-select>
                <span class="text-danger text-sm" v-show="errors.has('team')">{{  $t("team_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">  
          <p>{{ $t("startDate") }}<b style="color: #ff6141">*</b></p>
            <flat-pickr 
              :config="configdateTimePicker1" 
              @on-change="onFromChange" 
              v-validate="'required'"
              label-placeholder=" Date"
              v-model="startDate"
              name="startDate"
              class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('startDate')">{{  $t("startDate_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("endDate") }}<b style="color: #ff6141">*</b></p>
              <flat-pickr 
              v-validate="'required'"
              :config="configdateTimePicker2" 
              @on-change="onToChange"
              label-placeholder=" Date"
              v-model="endDate"
              name="endDate"
              class="w-full" />
              <span class="text-danger text-sm" v-show="errors.has('endDate')">{{  $t("endDate_error") }}</span>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("tasks") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                multiple 
                collapse-chips
                v-validate="'required'"
                :closeOnSelect="false"
                v-model="tasks_missions"
                data-vv-validate-on="blur"
                autocomplete
                name="tasks_missions"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showTasks">{{ $t("Voir") }}</vs-button>
            <span class="text-danger text-sm" v-show="errors.has('tasks_missions')">{{  $t("tasks_missions_error") }}</span>
          </div>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("riskProfile_ad") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                multiple
                collapse-chips
                v-model="riskProfiles"
                autocomplete
                name="riskProfile"
                class="w-full"
            >
          <vs-select-item :key="item5" :value="item5.id" :text="item5.name" v-for="item5 in riskProfiles_list" /></vs-select>
          </div>

          <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

          <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                multiple 
                v-validate="'required'"
                collapse-chips
                :closeOnSelect="false"
                v-model="sites"
                data-vv-validate-on="blur"
                autocomplete
                name="site"
                class="w-full"
            >
            <vs-select-item :key="item3" :value="item3.id" :text="item3.name" v-for="item3 in sites_missions" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('site')">{{  $t("site_error") }}</span>
          </div>

          <div v-if="this.equipe_check === false" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

            <p>{{ $t("headOfMissions") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
                v-if="this.equipe_check === false"
                multiple 
                v-validate="'required'"
                collapse-chips
                :closeOnSelect="false"
                v-model="headOfMissions"
                data-vv-validate-on="blur"
                autocomplete
                name="headOfMissions"
                class="w-full"
            >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.last_name" v-for="item4 in headOfMissionsList" /></vs-select>
            <span v-if="this.equipe_check === false" class="text-danger text-sm" v-show="errors.has('headOfMissions')">{{  $t("headOfMissions_error") }}</span>
          </div>

          <div v-if="this.equipe_check === true " class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("headOfMissions") }}<b style="color: #ff6141" >*</b> </p>
              <vs-select
              v-if="this.equipe_check === true"
                multiple 
                v-validate="'required'"
                collapse-chips
                :closeOnSelect="false"
                v-model="headOfMissions"
                data-vv-validate-on="blur"
                autocomplete
                name="headOfMissions"
                class="w-full"
            >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.last_name" v-for="item4 in  headOfMissions_liste" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('headOfMissions')">{{  $t("headOfMissions") }}</span>

            <span v-if="this.equipe_check === true " class="text-danger text-sm" v-show="errors.has('headOfMissions')">{{  $t("headOfMissions_error") }}</span>
          </div>

           <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
          <p>{{ $t("comonTransport") }}<b style="color: #ff6141" ></b> </p>
            <vs-checkbox 
            v-validate="'required'"
            v-model="comonTransport"
            autocomplete
            name="comonTransport"
            class="w-full">
            </vs-checkbox>
          <span class="text-danger text-sm" v-show="errors.has('comonTransport')">{{ $t("comonTransport_error") }}</span>
          </div>

          <div v-if="comonTransport" class="vx-col  md:w-1/2 sm:w-1/2 lg:w-1/2 xs:w-1/2">
          <label v-if="comonTransport" class="text-sm opacity-75">{{ $t("comonTransportDetails") }}<b style="color: #ff6141" >*</b></label>
          <vs-textarea
          name="comonTransportDetails"
          v-validate="'required'"
          v-model="comonTransportDetails"
          rows="3"
          class="w-full" />
          <span v-if="comonTransport" class="text-danger text-sm" v-show="errors.has('comonTransportDetails')">{{$t("comonTransportDetails_error") }}</span>
          </div>

          <!-- <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
                <span><b style="color:orange">{{ name_file1_3 }}</b></span>
                <input  name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".kmz,.kml" >
                <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">Choisir</vs-button>
          </div> -->
          <vx-card class="mt-10" no-shadow card-border>
                  <div class="vx-row ">
                    <div class="vx-col w-full">
                      <div class="flex items-end px-3">
                        <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
                        <span class="font-medium text-lg leading-none">{{ $t("info_equipe") }}</span>
                      </div>
                      <vs-divider />
                    </div>
                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p>{{ $t("question_modif_equipe") }}</p>
                      <vs-checkbox 
                          v-model="edit_team"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          autocomplete
                          name="edit_team"
                          class="w-full">
                    </vs-checkbox>
                    </div>
                    <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p>{{ $t("question_ajout_equipe") }}</p>
                      <vs-checkbox 
                          v-model="add_team"
                          data-vv-validate-on="blur"
                          v-validate="'required'"
                          autocomplete
                          name="add_team"
                          class="w-full">
                    </vs-checkbox>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("name") }}<b style="color: #ff6141"></b></p>
                      <vs-input
                      v-if="edit_team == true || add_team == true"
                        data-vv-validate-on="blur"
                        name="name"
                        v-model="name"
                        class="w-full"
                      />
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("teamLeader") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                      v-if="edit_team == true || add_team == true"
                        :closeOnSelect="false"
                        v-model="teamLeader"
                        data-vv-validate-on="blur"
                        autocomplete
                        name="teamLeader"
                        class="w-full"
                      >
                      <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
                      <p v-if="edit_team == true || add_team == true">{{ $t("company") }}<b style="color: #ff6141"></b></p>
                      <vs-select 
                        v-if="edit_team == true || add_team == true"
                          :disabled="role_dis"
                          :closeOnSelect="false"
                          v-model="company"
                          autocomplete
                          name="company"
                          class="w-full">
                     <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
                    </div>
                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p  v-if="edit_team == true || add_team == true">{{ $t("staffs") }}<b style="color: #ff6141" >*</b> </p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple
                          collapse-chips 
                          :closeOnSelect="false"
                          v-model="staff"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="staff"
                          class="w-full"
                      >
                    <vs-select-item :key="item5" :value="item5.id" :text="item5.fullName" v-for="item5 in staffs" /></vs-select>
          <!--                     <span class="text-danger text-sm" v-show="errors.has('staff')">{{  $t("staff") }}</span>
          -->                    
                    </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">

                      <p v-if="edit_team == true || add_team == true">{{ $t("tasks") }}<b style="color: #ff6141" >*</b> </p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="task"
                          data-vv-validate-on="blur"
                          autocomplete
                          name="task"
                          class="w-full"
                      >
                    <vs-select-item :key="item6" :value="item6.id" :text="item6.name" v-for="item6 in tasks" /></vs-select>
            <!--             <span class="text-danger text-sm" v-show="errors.has('task')">{{  $t("task") }}</span>
            -->     </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                      <p v-if="edit_team == true || add_team == true">{{ $t("vehicles") }}<b style="color: #ff6141" >*</b></p>
                          <vs-select
                            multiple 
                            collapse-chips
                            :closeOnSelect="false"
                            v-model="vehicle"
                            autocomplete
                            name="vehicle"
                            class="w-full"
                        >
                      <vs-select-item :key="item7" :value="item7.id" :text="item7.plateNumber" v-for="item7 in vehicles" /></vs-select>
          <!--                       <span class="text-danger text-sm" v-show="errors.has('vehicle')">{{  $t("vehicle") }}</span> 
          -->       </div>

                    <div v-if="edit_team == true || add_team == true" class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
                    <p v-if="edit_team == true || add_team == true">{{ $t("liftingEquipments") }}<b style="color: #ff6141" >*</b></p>
                        <vs-select
                          v-if="edit_team == true || add_team == true"
                          multiple 
                          collapse-chips
                          :closeOnSelect="false"
                          v-model="liftingEquipment"
                          autocomplete
                          name="liftingEquipment"
                          class="w-full"
                      >
                    <vs-select-item :key="item8" :value="item8.id" :text="item8.name" v-for="item8 in liftingEquipments" /></vs-select>
          <!--                   <span class="text-danger text-sm" v-show="errors.has('liftingEquipment')">{{  $t("liftingEquipment") }}</span>
          -->       </div>
                  </div>
                
                  <div v-if="edit_team == true || add_team == true" class="flex flex-wrap justify-between mt-8 mb-3">
                    <vs-button  v-if="add_team ==true"   @click="submitData_team">{{ $t("Ajouter") }}</vs-button>
                    <vs-button  v-if="edit_team ==true"  @click="submitData_team">{{ $t("Modifier") }}</vs-button>
                    <vs-button color="warning"  @click="intialise">{{ $t("Annuler") }}</vs-button>
                  </div>
          </vx-card>

                </div>
                <div class="vx-col md:w-full sm:w-full w-full ">
                <div class="flex flex-wrap justify-between mt-5" >
                  
                <vs-checkbox v-if="is_satisfied == true" class="float-right mt-4" :disabled="is_satisfied" v-model="is_satisfied" data-vv-validate-on="blur" name="medicalCheck">{{ $t("reunion_etat") }}</vs-checkbox>
            
                <vs-checkbox v-if="is_satisfied == false" class="float-right mt-4" :disabled="!have_meeting"  v-model="is_satisfiedAction" data-vv-validate-on="blur" name="medicalCheck">{{ $t("demande_etat") }}</vs-checkbox>
                
                <vs-button  color="primary" :disabled="have_meeting" class="float-right mt-4" @click="call_meeting()" >{{ $t("comite_sécurite") }}</vs-button>

                <vs-button color="success"  class="float-right mt-4 " @click="submitData()" >{{ $t("Valider") }}</vs-button>
                </div>
          </div>
      </div>

      <div v-if="activePrompt4"  class="clearfix">
        <div class="vx-row ">
          <vs-table stripe max-items="3" class="w-full  " pagination search :data="confirmation_list">
            <template slot="header">
              <h3>
                {{ $t("vehicles") }}
              </h3>
            </template>
            <template slot="thead">
              <vs-th>{{ $t("name") }}</vs-th>
              <vs-th>{{ $t("type") }}</vs-th>
              <vs-th>{{ $t("modele") }}</vs-th>
              <vs-th>{{ $t("num_plaque") }}</vs-th>
              <vs-th>{{ $t("confirmation") }}</vs-th>
              <vs-th>{{ $t("otherMeansOTransport") }}</vs-th>
              <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              
                <vs-td>
                  {{data[indextr].vehicle.name}}
                </vs-td>
                <vs-td v-if="getlang ==='fr'">
                  {{voiture_Object[data[indextr].vehicle.type]}}
                </vs-td>
                <vs-td v-if="getlang ==='en'">
                  {{voiture_Object_en[data[indextr].vehicle.type]}}
                </vs-td>
                <vs-td>
                  {{data[indextr].vehicle.model}}
                </vs-td>
                <vs-td>
                  {{data[indextr].vehicle.plateNumber}}
                </vs-td>
                <vs-td v-if="getlang ==='fr'">
                  {{confirmationObject[data[indextr].confirmed] }}
                </vs-td> 
                <vs-td v-if="getlang ==='en'">
                  {{confirmationObject_en[data[indextr].confirmed] }}
                </vs-td>
                <vs-td>
                  {{ data[indextr].otherMeansOTransport !== null ? data[indextr].otherMeansOTransport: '-'}}
                </vs-td> 
                
                <vs-td class="whitespace-no-wrap">
                  <div class="flex">
                    <vs-button  @click="openLightbox" @click.stop="Show_image_new(tr)"  color="primary" size="small" type="filled"  icon="folder">{{ $t("Voir") }}</vs-button>

                    <vs-button @click.stop="Confirmation_vehicule(tr)" color="success" size="small" type="filled" class="ml-2" icon="check">{{ $t("Confirmer") }}</vs-button>
                    
                  </div>
                </vs-td>
              </vs-tr>
              
            </template>
          </vs-table>
        </div>

        <vx-card class="mt-10" no-shadow card-border>
        <div class="vx-row ">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="FileTextIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Informations</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-col md:w-full sm:w-full w-full ">
        <div class="flex flex-wrap justify-between mt-5" >
        <vs-checkbox class="float-right mt-4" @change="change1()" v-model="awareOfOperationalProceduresByAllStaff"  name="Check1">{{ $t("prod_op") }}</vs-checkbox>     
        <vs-checkbox class="float-right mt-4" @change="change2()"  v-model="safetyBriefingRealisedForAllTeamMembers"  name="Check2">{{ $t("bref_sec") }}</vs-checkbox>
        <vs-checkbox class="float-right mt-4" @change="change3()"  v-model="hseToolboxtalkRealised"  name="Check3">{{ $t("pres_hse") }}</vs-checkbox>
        </div>
  <!--       <vs-button color="success"  class="float-right mt-4 " @click="submitData()" >Valider</vs-button>
  -->      </div>
        </vx-card>

      </div>
      
    <vs-popup
      class="calendar-event-dialog"
      :title="this.$t('tasks')"
        button-cancel = "border"
      :active.sync="activePromptDetailTasks">
      <vs-table max-items="3" pagination search :data="tasks_liste_profil">
      <template slot="thead">
        <vs-th>{{ $t("tasks") }}</vs-th>
        <vs-th>{{ $t("riskProfiles") }}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].name">
            {{data[indextr].name}}
          </vs-td>
          <vs-td :data="data[indextr].riskProfiles">
            <li v-for="riskProfile in data[indextr].riskProfiles" :key="riskProfile" class="product-name font-medium truncate">{{riskProfile.name}}</li>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    
    </vs-popup>
    
    <vs-popup
        class="calendar-event-dialog"
        :title="this.$t('comfirm_vehicule')"
        button-cancel = "border"
      :active.sync="activePromptCofirmationVehicule">

      <div class="p-6">
        <p class="pt-4">{{ $t("confirmation") }}<b style="color: #ff6141">*</b></p>
        <vs-checkbox 
            v-model="confirmed"
            name="confirmed"
            class="w-full">
          </vs-checkbox>
        
        <p class="pt-4">{{ $t("otherMeansOTransport") }}<b style="color: #ff6141" ></b> </p>
        <vs-input
          data-vv-validate-on="blur"
          name="otherMeansOTransport"
          v-model="otherMeansOTransport"
          class="w-full" />
          
        <p class="pt-4">{{ $t("fiche_insp") }}<b style="color: #ff6141" ></b> </p>
        <br>
        <span v-if="this.fileLenght" class="text-danger text-sm">
            <b style="color: #ff6141" >{{this.fileLenght}}{{ '  ' }}{{ 'fichier(s)'}}</b>
        </span>

          <vs-button color="primary" icon-pack="feather"  @click="Vehicule_Image=true">{{ $t("choisir") }}</vs-button>
          <vs-popup title="Fiche(s) d'inspection(s) du véhicule, et autres documents requis" :active.sync="Vehicule_Image" class="items-no-padding">
            <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
              <input  name="name_file1_4" type="file"  class="hidden" ref="uploadImgInput1_4" @change="updateCurrImg1_4" accept=".pdf,image/*" multiple >
              <vs-button @click="$refs.uploadImgInput1_4.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">{{ $t("Ajouter") }}</vs-button>
            </div>
            <div v-if="!update_Image" class="vx-row ">
            <vs-table stripe max-items="3" class="w-full  " pagination search :data="dataImg1">
            <template slot="header">

            </template>
            <template slot="thead">
            <vs-th hidden>N°</vs-th>
            <vs-th>{{ $t("name_file") }}</vs-th>
            <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden  :data="data[indextr]">
                {{indextr}}
              </vs-td>
              <vs-td :data="data[indextr].name">
                {{data[indextr].name}}
              </vs-td>           
              <vs-td class="whitespace-no-wrap">
                <div class="flex">
                  <vs-button @click.stop="deleteProduit(data[indextr].name)"  color="danger" size="small" icon-pack="feather" type="filled" class="ml-2" icon="icon-delete">{{ $t("Supprimer") }}</vs-button>

                </div>
              </vs-td>
            </vs-tr>
            </template>
            </vs-table>
            </div>

            <div v-if="update_Image"  class="vx-row ">
            <vs-table stripe max-items="3" class="w-full  " pagination search :data="dataImg1">
            <template slot="header">

            </template>
            <template slot="thead">
            <vs-th hidden>N°</vs-th>
            <vs-th>{{ $t("name_file") }}</vs-th>
            <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden  :data="data[indextr]">
                {{indextr}}
              </vs-td>
              <vs-td :data="data[indextr].name">
                {{data[indextr].name}}
              </vs-td>           
              <vs-td class="whitespace-no-wrap">
                <div class="flex">
                  <vs-button @click.stop="deleteProduit(data[indextr].name)"  color="danger" size="small" icon-pack="feather" type="filled" class="ml-2" icon="icon-delete">{{ $t("Supprimer") }}</vs-button>

<!--                     <vs-button color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">Voir</vs-button>    
-->              </div>
              </vs-td>
            </vs-tr>
            </template>
            </vs-table>
            </div>
            <!-- <input type="file" class="hidden" ref="updateImgInput1" @change="updateCurrImg1" multiple accept="image/*">
            <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2" multiple accept="image/*">
            <div class="carousel-example">
              <swiper  :options="swiperOption" style="height: 300px !important;">
                <swiper-slide  v-for="(data, index) in dataImg1" :key="index + '458'">
                  <vs-button
                    icon-pack="feather" icon="icon-trash" size="small" color="danger" @click="Delete_Image(data.id,index)">Supprimer
                  </vs-button>
                  <br>
                  <img  style="height: 300px !important;" class="responsive" :src= " data.url != null ? $store.state.image_url+data.url : data" alt="banner">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
            <br>
            <div v-if="this.update_Image === true " class="flex flex-wrap justify-between mb-3">
            <vs-button  icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput2.click()">{{ $t("Ajouter") }}</vs-button>
            <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div>
            <div v-if="this.update_Image === false " class="flex flex-wrap justify-between mb-3">
              <vs-button icon-pack="feather" icon="icon-image" @click="$refs.updateImgInput1.click()">Ajout Nouvel</vs-button>
              <vs-button icon-pack="feather" color="success" icon="icon-check" @click="Vehicule_Image = false">Valider</vs-button>
            </div> -->
          </vs-popup>
        
      </div>
      <div>
        <vs-button color="success"  class="float-right mt-4" @click="confirmation_vehiculeFunction()" >{{ $t("Valider") }}</vs-button>
      </div>
    </vs-popup>

    <vs-popup
    class="calendar-event-dialog"
      :title="this.$t('fiche_insp')"
      button-cancel = "border"
      :active.sync="activePrompt5">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="images">
            <template slot="header">

            </template>
            <template slot="thead">
            <vs-th hidden>N°</vs-th>
            <vs-th>{{ $t("name_file") }}</vs-th>
            <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

              <vs-td hidden  :data="data[indextr]">
                {{indextr}}
              </vs-td>
              <vs-td :data="data[indextr].name">
                {{data[indextr].name}}
              </vs-td>           
              <vs-td class="whitespace-no-wrap">
                <div class="flex">
    <!--<vs-button @click.stop="openfiles(data[indextr].name)"  color="danger" size="small" icon-pack="feather" type="filled" class="ml-2" icon="icon-delete">Supprimer</vs-button>
-->                     <vs-button  @click.stop="openfiles(data[indextr].url)"  color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">{{ $t("Voir") }}</vs-button>    
    </div>
    </vs-td>
    </vs-tr>
  </template>
  </vs-table>

    </vs-popup>

    <vs-popup
      class="calendar-event-dialog"
      :title="this.$t('comite_sécurite')"
      button-cancel = "border"
      :active.sync="activePrompt6">
      <div class="vx-col sm:w-full md:w-full lg:w-full xs:w-full">

      <p class="pt-4">{{ $t("raison") }}<b style="color: #ff6141" >*</b> </p>
        <vs-textarea
          data-vv-validate-on="blur"
          name="reason"
          v-model="reason"
          rows="3"
          class="w-full" />
      </div>

        <div class="vx-col sm:w-full md:w-full lg:w-full xs:w-full">
        <p>{{ $t("scheduled_date") }}<b style="color: #ff6141" >*</b></p>
        <flat-pickr 
        :config="configdateTimePicker"
        v-model="scheduled_date"
        data-vv-validate-on="blur"
        name="scheduled_date"
        class="w-full" 
          />
        </div>

        <div class="flex flex-wrap justify-between my-5">
        
        <vs-button  :disabled="!validmeeting" @click="meeting" color="primary" type="filled">{{ $t("Soumettre") }} </vs-button>

        <vs-button @click="CancelEvent" color="danger" type="filled">{{ $t("Annuler") }}</vs-button>
        </div>
    </vs-popup>

    <!--   <vs-popup title="Images du vehicle" :active.sync="showLightbox" class="items-no-padding">
        <div id="my-strictly-unique-vue-image-lightbox-carousel" style="text-align: center;">
          <vue-image-lightbox-carousel
            ref="lightbox"
            :show="showLightbox"
            @close="showLightbox = false"
            :images="images"
            @change="changeImage"
          >
          </vue-image-lightbox-carousel>
        </div>
      </vs-popup>
       -->
    </div>
  </template>
<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import VueImageLightboxCarousel from './SliderComponent'
import { Validator } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
const French = require('flatpickr/dist/l10n/fr.js').default.fr
const dict = {
  custom: {
    project: {
      required: 'Le champ projet  est obligatoire'
    },
    name_file1_3: {
      required: 'Le fichier  est obligatoire'
    },
    poNumber: {
      required: 'Le champ numéro de commande  est obligatoire'
    }, 
    woReference: {
      required: 'Le champ ordre de travail  est obligatoire'
    }, 
    numberOfSite: {
      required: 'Le champ nom nombre de site est obligatoire'
    }, 
    team : {
      required: 'Le champ nom d\'équipe  est obligatoire'
    }, 
    startDate: {
      required: 'Le champ date de début est obligatoire'
    },
    endDate: {
      required: 'Le champ date de fin  est obligatoire'
    }, 
    tasks_missions: {
      required: 'Le champ tâches est obligatoire'
    }, 
    site: {
      required: 'Le champ site est obligatoire'
    }, 
    headOfMissions: {
      required: 'Le champ chef de mission est obligatoire'
    },
    name:{
      required: 'Le champ nom d\'équipe est obligatoire'

    },
    vehicle: {
      required: 'Le champ véhicule est obligatoire'
    }, 
    staff: {
      required: 'Le champ personnel est obligatoire'
    },
    task: {
      required: 'Le champ tâche est obligatoire'
    },
    liftingEquipment: {
      required: 'Le champ équipement de levage est obligatoire'
    }
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      comonTransport:false,
      comonTransportDetails:'',
      managers:[],
      manager:'',
      action_button:'',
      missionControle_data:'',
      teamLeaders:[],
      companies:[],
      role_dis: false,
      company : '',
      teamLeader:'',
      user_role:'',
      awareOfOperationalProceduresByAllStaff:false,
      safetyBriefingRealisedForAllTeamMembers:false,
      hseToolboxtalkRealised:false,
      is_satisfied:false,
      have_meeting:false,
      is_satisfiedAction:false,
      reason:'',
      scheduled_date:'',
      configdateTimePicker: {
        dateFormat: 'Y-m-d H:i:s',
        minDate: new Date(),
        enableTime: true,
        enableSeconds: true,
        locale: French,
        noCalendar: false
      },
      produits_computed:[],
      id_mission_vehicule:'',
      confirmationObject: {
        true: 'Oui',
        false: 'Non'
      },
      confirmationObject_en: {
        true: 'True',
        false: 'False'
      },
      planification_mission_data : {},
      Vehicule_Image:false,
      showLightbox: false,
      proofs:[],
      images: [],
      dataImg: [],
      AddNewdataImg_file: [],
      update_Image:true,
      dataImg1: [],
      dataImg1_delete: [],
      dataImg1_file: [],
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      confirmation_Id:null,
      confirmed : 0,
      otherMeansOTransport : '',
      confirmationProofs : [],
      confirmationProofs_remove : [],
     
      confirmation_list : [],

      add_team :false,
      edit_team :false,
      configdateTimePicker1: {
        enableTime: 'fasle',
        minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker11: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker22: {
        enableTime: 'fasle',
        dateFormat: 'Y-m-d',
        locale: French
      },
      configdateTimePicker2: {
        enableTime: 'fasle',
        minDate: null,
        dateFormat: 'Y-m-d',
        locale: French
      },
      activePromptCofirmationVehicule:false,
      activePromptDetailTasks:false,
      activePrompt6:false,
      activePrompt5:false,
      activePrompt4:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,
      voiture_Object: {
        truck: 'Camion',
        car: 'Voiture',
        bus: 'Bus'
      },
      voiture_Object_en: {
        truck: 'Truck',
        car: 'Car',
        bus: 'Bus'
      },
      poNumber:'',
      woReference:'',
      numberOfSite:0,
      team:'',
      startDate:null,
      endDate:null,
      tasks_missions:[],
      sites:[],
      headOfMissions:[],
      headOfMissions_liste:[],
      headOfMissions_liste_add:[],
      headOfMissions_liste_add_team:[],

      sites_missions:[],

      data_show:{},
      add_team_detail : false,

      id:'',
      name:'',
      vehicle: [],
      task:[],
      staff: [],
      liftingEquipment:[],
      teams: [],
      missions:[],
      vehicles: [],
      tasks:[],
      staffs: [],
      liftingEquipments:[],
      selected: [],
      riskProfiles:[],
      headOfMissionsList:[],
      riskProfiles_list:[],
      tasks_liste_profil:[],
      isMounted: false,
      equipe_check:false,
      first_time_check:true,
      itemsPerPage: 20,
      dataImg1_3:'',
      name_file1_3:'',
      file_view:'',
      fileLenght:'',
      meeting_idCall:'',
      projects:[],
      project:''
     
    }
  },
  components: {
    VueImageLightboxCarousel,
    swiper,
    swiperSlide,
    flatPickr
  },
  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    validmeeting () {
      return this.id_mission !== '' && this.reason !== '' && this.scheduled_date !== ''
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.missions.length
    }
  },
  watch: {
    is_satisfiedAction (value) {
      if (value) {
        const meeting_id = this.meeting_idCall
        this.$http.post('satisfyRequest/', {meeting_id})
          .then((response) => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: response.data.message,
              color: 'success',
              position: 'top-center'
            })
            this.is_satisfied = true
            this.CancelEvent()
          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()
            this.CancelEvent()

          })
      }
    },
    tasks_missions (value) {
      if (value) {
        this.tasks_liste_profil = []
        for (let i = 0; i < value.length; i++) {
          this.$http.get(`tasks/${value[i]}/`)
            .then((response) => {
              this.tasks_liste_profil.push(response.data)
              this.$vs.loading.close()
            })
            .catch(() => {
              this.$vs.loading.close()
            })
        }
      } else {
        this.tasks_liste_profil = []
        for (let i = 0; i < value.length; i++) {
          this.$http.get(`tasks/${value[i]}/`)
            .then((response) => {
              this.tasks_liste_profil.push(response.data)
              this.$vs.loading.close()
            })
            .catch(() => {
              this.$vs.loading.close()
            })
        }
      }
    },
    edit_team (value) {
      if (value === true) {
        this.intialise()
        //this.edit_team = true
        this.add_team = false
        this.ShowData()
      } else {
        this.intialise()
      }
    },
    add_team (value) {
      if (value) {
        this.intialise()
        this.edit_team = false
        
      }
    },
    team (value) {
      if (value) {
        this.headOfMissions_liste = []
        const team = value
        this.$vs.loading()
        this.$http.post('headOfMissions-by-teamCompany/', {team})
          .then((response) => {
            this.headOfMissions_liste = response.data
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
        if (this.add_team_detail === true) {
          //modification mission
          this.headOfMissions_liste_objet = []
          this.intialise()
          this.$http.get(`teams/${value}/`)
            .then((response) => {
              this.headOfMissions_liste_objet = response.data
              this.name = this.headOfMissions_liste_objet.name
              this.id   = this.headOfMissions_liste_objet.id
              this.company = this.headOfMissions_liste_objet.company.id
              this.teamLeader = this.headOfMissions_liste_objet.teamLeader.id
              for (let i = 0; i < this.headOfMissions_liste_objet.staffs.length; i++) {
                this.staff.push(this.headOfMissions_liste_objet.staffs[i].id)
         
              } 
              for (let i = 0; i < this.headOfMissions_liste_objet.tasks.length; i++) {
                this.task.push(this.headOfMissions_liste_objet.tasks[i].id)
         
              }
              for (let i = 0; i < this.headOfMissions_liste_objet.vehicles.length; i++) {
                this.vehicle.push(this.headOfMissions_liste_objet.vehicles[i].id)
         
              } 
              for (let i = 0; i < this.headOfMissions_liste_objet.liftingEquipments.length; i++) {
                this.liftingEquipment.push(this.headOfMissions_liste_objet.liftingEquipments[i].id)
              } 
              this.$vs.loading.close()
            })
            .catch(() => {
              this.$vs.loading.close()
            })
        }
        if (this.add_team_detail === false) {
        //Ajout mission
          this.headOfMissions_liste_add = []
          //this.headOfMissions_liste_add_team = []
          this.intialise()
          this.$http.get(`teams/${value}/`)
            .then((response) => {
              this.headOfMissions_liste_add = response.data
              this.name =  this.headOfMissions_liste_add.name
              this.id   =  this.headOfMissions_liste_add.id
              this.company = this.headOfMissions_liste_add.company.id
              this.teamLeader = this.headOfMissions_liste_add.teamLeader.id
              //this.headOfMissions_liste_add_team = this.headOfMissions_liste_add.staffs
              for (let i = 0; i <  this.headOfMissions_liste_add.staffs.length; i++) {
                this.staff.push(this.headOfMissions_liste_add.staffs[i].id)

              } 
              for (let i = 0; i <  this.headOfMissions_liste_add.tasks.length; i++) {
                this.task.push(this.headOfMissions_liste_add.tasks[i].id)
         
              }
              for (let i = 0; i <  this.headOfMissions_liste_add.vehicles.length; i++) {
                this.vehicle.push(this.headOfMissions_liste_add.vehicles[i].id)
         
              } 
              for (let i = 0; i <  this.headOfMissions_liste_add.liftingEquipments.length; i++) {
                this.liftingEquipment.push(this.headOfMissions_liste_add.liftingEquipments[i].id)
              }
              this.$vs.loading.close()
            })
            .catch(() => {
              this.$vs.loading.close()
            })
        } 
      }
    
    }
  },
  methods: {
    change1 () {
      const mission = this.mission_data_up.id
      const awareOfOperationalProceduresByAllStaff =  this.awareOfOperationalProceduresByAllStaff
      const safetyBriefingRealisedForAllTeamMembers = this.safetyBriefingRealisedForAllTeamMembers
      const hseToolboxtalkRealised = this.hseToolboxtalkRealised
      if (this.awareOfOperationalProceduresByAllStaff) {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: this.$t('pro_operationnelle'),
              color: 'success',
              position: 'top-center'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      } else {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: this.$t('pro_operationnelle_non'),
              color: 'warning',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      }
    },
    change2 () {
      const mission = this.mission_data_up.id
      const awareOfOperationalProceduresByAllStaff =  this.awareOfOperationalProceduresByAllStaff
      const safetyBriefingRealisedForAllTeamMembers = this.safetyBriefingRealisedForAllTeamMembers
      const hseToolboxtalkRealised = this.hseToolboxtalkRealised
      if (this.safetyBriefingRealisedForAllTeamMembers) {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: this.$t('bref_sec_conf'),
              color: 'success',
              position: 'top-center'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      } else {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              ttitle: this.$t('confirmation'),
              text: this.$t('bref_sec_conf'),
              color: 'warning',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      }
    },
    change3 () {
      const mission = this.mission_data_up.id
      const awareOfOperationalProceduresByAllStaff =  this.awareOfOperationalProceduresByAllStaff
      const safetyBriefingRealisedForAllTeamMembers = this.safetyBriefingRealisedForAllTeamMembers
      const hseToolboxtalkRealised = this.hseToolboxtalkRealised
      if (this.hseToolboxtalkRealised) {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: this.$t('pre_boite_oui'),
              color: 'success',
              position: 'top-center'
            })
            
          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      } else {
        this.$http.post('confirmAllVehiclesValidation/', {mission, awareOfOperationalProceduresByAllStaff, safetyBriefingRealisedForAllTeamMembers, hseToolboxtalkRealised })
          .then(() => {
            this.$vs.loading.close()
            this.$vs.notify({
              time: 4000,
              title: this.$t('confirmation'),
              text: this.$t('pre_boite_non'),
              color: 'warning',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
          .catch((error) => {
            this.$vs.notify({
              time: 4000,
              title: this.$t('error'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-center'
            })
            this.$vs.loading.close()

          })
      }
    },
    initialise_meeting () {
      this.reason = ''
      this.scheduled_date = ''
    },
    call_meeting () {
      this.initialise_meeting()
      this.activePrompt6 = true
    },
    CancelEvent () {
      this.initialise_meeting()
      this.activePrompt6 = false
    },
    meeting () {
      const mission = this.id_mission
      const reason =  this.reason
      const scheduled_date = this.scheduled_date
      
      this.$http.post('meetings/', {mission, reason, scheduled_date})
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 4000,
            title: this.$t('confirmation'),
            text: this.$t('send_mail'),
            color: 'success',
            position: 'top-center'
          })
          this.have_meeting = true
          this.meeting_idCall = response.data.id
          this.CancelEvent()
        })
        .catch((error) => {
          this.$vs.notify({
            time: 4000,
            title: this.$t('error'),
            text: error.response.data.error,
            color: 'danger',
            position: 'top-center'
          })
          this.$vs.loading.close()
          this.CancelEvent()

        })
    },
    
    openImage () {
      if (this.file_view) {
        //window.open(`https://jra.mitigarisk.com/digira/`, '_blank')
        const link = document.createElement('a')
        link.href = `https://jra.mitigarisk.com/digira/${this.file_view}`
        link.download = this.name_file1_3
        document.body.appendChild(link)
        link.click()
      }
    },
    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        this.dataImg1_3 = input.target.files[0]
        this.name_file1_3 = input.target.files[0].name
      }
    },
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    Confirmation_vehicule (item) {
      this.initialiseConfirmation()
      this.confirmation_Id = item.id
      const length = item.confirmationProofs.length
      if (length === 0) {
        this.update_Image = false
        if (item.confirmed === true) {
          this.confirmed = 1
        }
        if (item.confirmed === false) {
          this.confirmed = 0
        }
        this.otherMeansOTransport = item.otherMeansOTransport
      } else {

        for (let i = 0; i < length; i++) {
          const object = 
            {   'id':(item.confirmationProofs[i].id),
              'name':(item.confirmationProofs[i].url).substring(16)
            }
          this.dataImg1.push(object)
          //this.dataImg1_file.push(object)

        }
        if (item.confirmed === true) {
          this.confirmed = 1
        }
        if (item.confirmed === false) {
          this.confirmed = 0
        }
        this.otherMeansOTransport = item.otherMeansOTransport
        this.update_Image = true
      }
      this.activePromptCofirmationVehicule = true
    },
    
    confirmation_vehiculeFunction () {
      //if (this.confirmed) {
      this.$vs.loading()
      const formdata = new FormData()
      formdata.append('confirmed', this.confirmed)
      formdata.append('otherMeansOTransport', this.otherMeansOTransport)
      //Ajout d'image
      if (this.dataImg1_file.length) {
        const length = this.dataImg1_file.length
        for (let i = 0; i < length; i++) {
          formdata.append('confirmationProofs', this.dataImg1_file[i],  this.dataImg1_file[i].name)
        }
      }
      //Ajout de nouvelle d'image
      let i = 0
      if (this.AddNewdataImg_file.length) {
        const length = this.AddNewdataImg_file.length
        for (i = 0; i < length; i++) {
          formdata.append('confirmationProofs', this.AddNewdataImg_file[i], this.AddNewdataImg_file[i].name)
        }
      } else {
        formdata.append('confirmationProofs', '')
      }
      //suppression d'image
      if (this.dataImg1_delete.length) {
        for (i = 0; i < this.dataImg1_delete.length; i++) {
          formdata.append('confirmationProofs_remove', this.dataImg1_delete[i])
        }
      } else {
        formdata.append('confirmationProofs_remove', '')
      }

      this.$http.put(`confirmations/${this.confirmation_Id}/`, formdata)
        .then(() => {
          this.$vs.notify({
            time: 4000,
            title: this.$t('confirmation'),
            text: this.$t('comf_vehicule'),
            color: 'success',
            position: 'top-center'
          })
          this.getOneMission(this.id_mission_vehicule) 
          this.activePromptCofirmationVehicule = false         
          this.initialiseConfirmation()

        })
        .catch(() => {
          this.$vs.notify({
            time: 4000,
            title: this.$t('attention'),
            text: this.$t('comf_vehicule_error'),
            color: 'warning',
            position: 'top-center'
          })
        })
      /*} else {
        this.$vs.notify({
          time: 4000,
          title: 'ATTENTION',
          text: 'CHOISIR LA CONFIRMATION',
          color: 'warning',
          position: 'top-center'
        })
      } */
    },
    initialiseConfirmation () {
      this.confirmation_Id = null
      this.fileLenght = ''
      this.confirmed = 0
      this.otherMeansOTransport = ''
      this.confirmationProofs = []
      this.confirmationProofs_remove = []
      this.dataImg1 = []
      this.dataImg1_file = []
      this.dataImg1_delete = []
      this.AddNewdataImg_file = []
    },
    Show_image (tr) {
      this.images = []
      const image_url = 'https://jra.mitigarisk.com/digira/'
      if (tr.confirmationProofs) {
        const length = tr.confirmationProofs.length
        for (let i = 0; i < length; i++) {
          this.images.push(image_url + tr.confirmationProofs[i].url)
        }
      } else {
        this.images = []
      }
    },
    Show_image_new (tr) {
      this.activePrompt5 = true
      this.images = []
      const image_url = 'https://jra.mitigarisk.com/digira/'
      if (tr.confirmationProofs) {
        const length = tr.confirmationProofs.length
        for (let i = 0; i < length; i++) {
          const object = 
            {   'id':(tr.confirmationProofs[i].id),
              'name':(tr.confirmationProofs[i].url).substring(8),
              'url':(image_url + tr.confirmationProofs[i].url)
            }
          this.images.push(object)
        }
      } else {
        this.images = []
      }
    },
    openfiles (file) {
      window.open(file, '_blank')
    },
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },
    openLightbox () {
      this.showLightbox = true
      this.$refs.lightbox.showImage()
    },
    async Delete_Image (data, key) {
      if (data) {
        this.dataImg1_delete.push(parseInt(data))
        this.dataImg1.splice(key, 1)

      } else {
        this.dataImg1.splice(key, 1)
        this.dataImg1_file.splice(key, 1)
        this.AddNewdataImg_file.splice(key, 1)
      }
    },
    updateCurrImg1 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.dataImg1_file.unshift(this.$refs.updateImgInput1.files[i])

          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    updateCurrImg2 (input) {
      if (input.target.files && input.target.files[0]) {
        const length = input.srcElement.files.length
        for (let i = 0; i < length; i++) {
          const reader = new FileReader()
          reader.onload = e => {
            // unshift
            this.dataImg1.unshift(e.target.result)
            this.AddNewdataImg_file.unshift(this.$refs.updateImgInput2.files[i])
          }
          reader.readAsDataURL(input.target.files[i])
        }
      }
    },
    updateCurrImg1_4 (input) {
      if (input.target.files && input.target.files.length >0) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.dataImg1.push(input.target.files[i])
          this.dataImg1_file.push(input.target.files[i])
        }
        this.fileLenght =  this.dataImg1_file.length
      }
    },
    deleteProduit (name) {
      this.REMOVE_ITEM_IMAGE(this.dataImg1, name)
      this.REMOVE_ITEM_IMAGE(this.dataImg1_file, name)

      this.$vs.notify({
        color:'success',
        icon:'delete',
        text: this.$t('delete_file'),
        position:'top-right'
      })
    
    },
    REMOVE_ITEM_IMAGE (produits, produit) {
      for (let j = 0; j <  produits.length; j++) {
        let prod = ''
        let prodIndex = ''
        let p1 = ''
        let p2 = ''
        prod = produits[j]
        prodIndex = produits.indexOf(produits[j])
        p1 = prod.name
        p2 = produit
        if (p1 === p2) {
          this.dataImg1_delete.push(parseInt(prod.id))
          produits.splice(prodIndex, 1)
        } 
      }
    },
   
    showTasks () {
      this.activePromptDetailTasks = true
    },
    onFromChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker2, 'minDate', dateStr)
    },
    onToChange (selectedDates, dateStr) {
      this.$set(this.configdateTimePicker1, 'maxDate', dateStr)
    },
    addNewData_mission () {
      this.$validator.reset()
      this.add_team_detail = false
      this.intialise_mission()
      //this.intialise()
      this.Check_update = false
      this.activePrompt1 = true
    },
    confirmation_show (id, data) {
      this.id_mission_vehicule = id
      this.confirmation_list = []
      for (let i = 0; i < data.length; i++) {
        this.confirmation_list.push(data[i])
         
      }
      this.awareOfOperationalProceduresByAllStaff =  this.mission_data_up.awareOfOperationalProceduresByAllStaff
      this.safetyBriefingRealisedForAllTeamMembers = this.mission_data_up.safetyBriefingRealisedForAllTeamMembers
      this.hseToolboxtalkRealised = this.mission_data_up.hseToolboxtalkRealised
      
      this.update_Image = false
      this.activePrompt4 = true
    },
    quit_mission () {
      this.activePrompt2 = false
      this.$validator.reset()
      this.intialise_mission()
      this.intialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
    ShowData_mission (data) {
      this.intialise_mission()
      this.intialise()
      this.getAllheadOfMissionsList(data.team.id)
      this.data_show = data
      this.id_mission = data.id
      this.project = data.project.id
      this.manager = data.author.id
      this.comonTransport = data.comonTransport
      this.comonTransportDetails = data.comonTransportDetails
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.poNumber = data.poNumber
      this.woReference = data.woReference 
      this.numberOfSite = data.numberOfSite
      this.team = data.team.id
      //this.name_file1_3 = data.kmzFile.substring(47)
      //this.file_view = data.kmzFile.substring(39)

      for (let i = 0; i < data.riskProfiles.length; i++) {
        this.riskProfiles.push(data.riskProfiles[i].id)
      }
      
      for (let i = 0; i < data.headOfMissions.length; i++) {
        this.headOfMissions.push(data.headOfMissions[i].id)
         
      }
      

      for (let i = 0; i < data.sites.length; i++) {
        this.sites.push(data.sites[i].id)
         
      }  
      for (let i = 0; i < data.tasks.length; i++) {
        this.tasks_missions.push(data.tasks[i].id)
         
      }
      this.activePrompt2 = true
      this.ShowData()
    },
    ShowData () {
      this.name = this.data_show.team.name
      this.id   = this.data_show.team.id
      for (let i = 0; i < this.data_show.team.staffs.length; i++) {
        this.staff.push(this.data_show.team.staffs[i].id)
         
      } 
      for (let i = 0; i < this.data_show.team.tasks.length; i++) {
        this.task.push(this.data_show.team.tasks[i].id)
         
      }
      for (let i = 0; i < this.data_show.team.vehicles.length; i++) {
        this.vehicle.push(this.data_show.team.vehicles[i].id)
         
      } 
      for (let i = 0; i < this.data_show.team.liftingEquipments.length; i++) {
        this.liftingEquipment.push(this.data_show.team.liftingEquipments[i].id)
      } 
    },
   
    editData_mission (data) {
      this.intialise_mission()
      this.intialise()
      this.getAllheadOfMissionsList(data.team.id)
      //this.update_Image = true
      if (data.meetings.length) {
        this.meeting_idCall = data.meetings[0].id
      }
      this.add_team_detail = true
      this.data_show = data
      this.manager = data.author.id
      this.comonTransport = data.comonTransport
      this.comonTransportDetails = data.comonTransportDetails
      this.is_satisfied = data.is_satisfied
      this.have_meeting = data.have_meeting
      this.id_mission = data.id
      this.project = data.project.id
      this.startDate = data.startDate
      this.endDate = data.endDate
      this.poNumber = data.poNumber
      this.woReference = data.woReference 
      this.numberOfSite = data.numberOfSite
      this.team = data.team.id
      //this.name_file1_3 = data.kmzFile.substring(47)

      
      for (let i = 0; i < data.riskProfiles.length; i++) {
        this.riskProfiles.push(data.riskProfiles[i].id)
      }

      for (let i = 0; i < data.headOfMissions.length; i++) {
        this.headOfMissions.push(data.headOfMissions[i].id)
         
      }

      for (let i = 0; i < data.sites.length; i++) {
        this.sites.push(data.sites[i].id)
         
      }  
      for (let i = 0; i < data.tasks.length; i++) {
        this.tasks_missions.push(data.tasks[i].id)
         
      }
      this.Check_update = true
      this.activePrompt3 = true
    },
   
    intialise () {
      this.id = ''
      /* this.company = ''
      this.teamLeader = ''  */
      this.name = ''
      this.staff = []
      this.task = []
      this.vehicle = []
      this.liftingEquipment = []

    },
    intialise_mission () {
      this.project = ''
      this.comonTransport = false
      this.comonTransportDetails = ''
      this.managerSubcontractor = ''
      this.is_satisfiedActiont = false
      this.have_meeting = false
      this.meeting_idCall = ''
      this.file_view = ''
      this.dataImg1_3 = ''
      this.name_file1_3 = ''
      this.first_time_check = true
      this.equipe_check = false
      this.add_team = false
      this.edit_team = false
      this.id_mission = null
      this.poNumber = ''
      this.woReference = ''
      this.numberOfSite = 0
      this.team = null
      this.startDate = null
      this.endDate = null
      this.dataImg1 = []
      this.tasks_missions = []
      this.sites = []
      this.headOfMissions = []
      this.riskProfiles = []
      this.headOfMissionsList = []
      this.project = ''
      this.projects = []
      this.activePrompt2 = false
      this.activePrompt1 = false
      this.activePrompt3 = false
    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`missions/${id}/`)
            .then(response => {
              this.getAllMissions()
              window.getPrendTaCom.success(this.$t('suppression_valid_mission'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('suppression_error_mission')})
            })
        }
      })
  
    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading()
          if (this.Check_update === false) {
            this.input = {
              project:this.project,
              poNumber : this.poNumber,
              managerSubcontractor:this.manager,
              comonTransport : this.comonTransport,
              comonTransportDetails : this.comonTransportDetails,
              woReference : this.woReference,
              numberOfSite : parseInt(this.numberOfSite),
              team :  parseInt(this.team),
              startDate : this.startDate,
              endDate : this.endDate,
              tasks : this.tasks_missions,
              sites : this.sites,
              headOfMissions : this.headOfMissions,
              riskProfiles : this.riskProfiles

            }
          } else if (this.Check_update === true) {
            this.input = {
              id:this.id_mission,
              project:this.project,
              managerSubcontractor:this.manager,
              comonTransport : this.comonTransport,
              comonTransportDetails : this.comonTransportDetails,
              poNumber : this.poNumber,
              woReference : this.woReference,
              numberOfSite : parseInt(this.numberOfSite),
              team :  parseInt(this.team),
              startDate : this.startDate,
              endDate : this.endDate,
              tasks : this.tasks_missions,
              sites : this.sites,
              headOfMissions : this.headOfMissions,
              riskProfiles : this.riskProfiles

            }
          }
          /* const formdata = new FormData()
          if (this.dataImg1_3) {
            formdata.append('kmzFile', this.dataImg1_3,  this.dataImg1_3.name)
          }
          const keys = ['poNumber', 'woReference', 'numberOfSite', 'team', 'startDate', 'endDate', 'tasks', 'sites', 'headOfMissions', 'riskProfiles']
          const length = keys.length
          for (let j = 0; j < length; j++) {
            const item = keys[j]
            formdata.append(item, this.input[item])
          } */
         
          let url = 'missions/'
          let methods = 'post'
          const message = {
            error: this.$t('save_error'),            
            success: this.$t('save_mission')
          }
          if (this.id_mission) {
            url += `${this.id_mission}/`
            methods = 'put'
            message.success = this.$t('update_mission')
  
          }
  
          this.$http[methods](url, this.input)
            .then((response) => {
              window.Missions.getAllMissions()
              this.intialise_mission()
              this.intialise()
              this.missionStep()
              window.getPrendTaCom.success(message.success, response)
              
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'poNumber') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('poNumber'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'poNumber') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('poNumber'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'numberOfSite') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('numberOfSite'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'team') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('team'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'startDate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('startDate'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'endDate') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('endDate'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'startDate') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('startDate'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'tasks') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('tasks'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'headOfMissions') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('headOfMissions'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'managerSubcontractor') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('managerSubcontractor'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'comonTransport') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('comonTransport'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'comonTransportDetails') {
                  this.$vs.notify({
                    time: 10000,
                    title: 'ATTENTION',
                    text: this.$t('comonTransportDetails'),
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 15000,
                title: this.$t('echec_mission_save'),
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        }
      }) 
        
    },
    submitData_team () {
      if (this.company !== '' && this.teamLeader !== '' && this.name !== '' && this.staff.length > 0 && this.vehicle.length > 0 && this.liftingEquipment.length > 0) {
        this.$vs.loading()
        if (this.Check_update === false) {
          this.input = {
            name : this.name,
            company:this.company,
            teamLeader:this.teamLeader,
            tasks : this.task,
            staffs : this.staff,
            vehicles : this.vehicle,
            liftingEquipments : this.liftingEquipment
          }
        } else if (this.Check_update === true) {
          this.input = {
            id:this.id,
            name : this.name,
            company:this.company,
            teamLeader:this.teamLeader,
            tasks : this.task,
            staffs : this.staff,
            vehicles : this.vehicle,
            liftingEquipments : this.liftingEquipment
          }
        }
        let url = 'teams/'
        let methods = 'post'
        const message = {
          error: this.$t('save_error'),            
          success: this.$t('team_save') 
        }
        if (this.id) {
          url += `${this.id}/`
          methods = 'put'
          message.success = this.$t('team_update') 
  
        }
  
        this.$http[methods](url, this.input)
          .then((response) => {
            this.getAllTeams()
            this.intialise()
            this.team = []
            this.headOfMissions =  []
            //this.edit_team = false
            //this.add_team = false
            if (this.user_role !== 'managerSubcontractor') {
              this.company = ''
            }
            this.teamLeader = ''
            window.getPrendTaCom.success(message.success, response)
            
          })
          .catch((error) => {
            const clefs = Object.keys(error.response.data)
            for (let i = 0; i < clefs.length; i++) {
              const item = clefs[i]
              if (item === 'name') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('name'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'tasks') {
                this.$vs.notify({
                  time: 10000,
                  title: 'ATTENTION',
                  text: this.$t('tasks'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'staffs') {
                this.$vs.notify({
                  time: 10000,
                  title: 'ATTENTION',
                  text: this.$t('staffs'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'vehicles') {
                this.$vs.notify({
                  time: 10000,
                  title: 'ATTENTION',
                  text: this.$t('vehicles'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'liftingEquipments') {
                this.$vs.notify({
                  time: 10000,
                  title: 'ATTENTION',
                  text:this.$t('team_update'),
                  color: 'warning',
                  position: 'top-center'
                })
              }
            }
            this.$vs.notify({
              time: 15000,
              title:this.$t('save_error_team'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-right'
            })
            this.$vs.loading.close()
          })
      } if (this.name === '') {
        this.$vs.notify({
          time: 10000,
          title: 'ATTENTION',
          text: this.$t('name'),
          color: 'warning',
          position: 'top-center'
        })
      } if (this.staff.length === 0) {
        this.$vs.notify({
          time: 10000,
          title: 'ATTENTION',
          text: this.$t('staff'),
          color: 'warning',
          position: 'top-center'
        })
      } if (this.vehicle.length === 0) {
        this.$vs.notify({
          time: 10000,
          title: 'ATTENTION',
          text: this.$t('vehicle'),
          color: 'warning',
          position: 'top-center'
        })
      } if (this.liftingEquipment.length === 0) {
        this.$vs.notify({
          time: 10000,
          title: 'ATTENTION',
          text: this.$t('liftingEquipment'),
          color: 'warning',
          position: 'top-center'
        })
      }
        
    },
    getAllVehicles () {
      let assigned = ''
      this.$vs.loading()
      this.$http.get('vehicles/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            if (event.assigned === true) {
              assigned = '(1)'
            } else {
              assigned = '(0)'
            }
            this.vehicles.push({
              id: event.id,
              plateNumber:(`${event.plateNumber}${assigned}`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllLiftingEquipments () {
      this.$vs.loading()
      let assigned = ''
      let norm = ''
      this.$http.get('liftingEquipments/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            if (event.assigned === true) {
              assigned = '(1)'
            } else {
              assigned = '(0)'
            }
            if (event.otherNorm) {
              norm = event.otherNorm
            } else {
              norm = event.equipmentType.norm
            }
            this.liftingEquipments.push({
              id: event.id,
              name:(`${event.equipmentType.name}-${norm}${assigned}`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllstaffs () {
      this.$vs.loading()
      this.$http.get('staffs/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            this.staffs.push({
              id: event.id,
              fullName:(`${event.fullName}-(${event.function.name})`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAlltasks () {
      this.$vs.loading()
      this.$http.get('tasks/')
        .then((response) => {
          this.tasks = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllTeams () {
      this.$vs.loading()
      this.$http.get('teams/')
        .then((response) => {
          this.teams = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllSites () {
      this.$vs.loading()
      this.$http.get('sites/')
        .then((response) => {
          this.sites_missions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllMissions () {
      this.$vs.loading()
      this.$http.get('missions/')
        .then((response) => {
          this.missions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getOneMission (id) {
      this.$vs.loading()
      this.confirmation_list = []
      this.$http.get(`missions/${id}/`)
        .then((response) => {
          for (let i = 0; i < response.data.missionConfirmation.length; i++) {
            this.confirmation_list.push(response.data.missionConfirmation[i])
          }
          //a corriger par une fonction store
          this.getAllMissions()
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllriskProfiles () {
      this.$vs.loading()
      this.$http.get('riskProfiles/')
        .then((response) => {
          this.riskProfiles_list = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllheadOfMissionsList (id) {
      this.$vs.loading()
      const team = id
      this.$http.post('headOfMissions-by-teamCompany/', {team})
        .then((response) => {
          this.headOfMissionsList = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllProjects () {
      this.$vs.loading()
      this.$http.get('projects/')
        .then((response) => {
          this.projects = response.data          
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    remove_chip (data) {
      if (this.first_time_check === true) {
        this.$http.get(`teams/${data}/`)
          .then((response) => {
            this.headOfMissions_liste_objet = response.data
            //this.headOfMissions_liste = response.data.staffs
            this.name = this.headOfMissions_liste_objet.name
            this.id   = this.headOfMissions_liste_objet.id
            for (let i = 0; i < this.headOfMissions_liste_objet.staffs.length; i++) {
              this.staff.push(this.headOfMissions_liste_objet.staffs[i].id)
         
            } 
            for (let i = 0; i < this.headOfMissions_liste_objet.tasks.length; i++) {
              this.task.push(this.headOfMissions_liste_objet.tasks[i].id)
         
            }
            for (let i = 0; i < this.headOfMissions_liste_objet.vehicles.length; i++) {
              this.vehicle.push(this.headOfMissions_liste_objet.vehicles[i].id)
         
            } 
            for (let i = 0; i < this.headOfMissions_liste_objet.liftingEquipments.length; i++) {
              this.liftingEquipment.push(this.headOfMissions_liste_objet.liftingEquipments[i].id)
            } 
            this.$vs.loading.close()
          })
          .catch(() => {
            this.$vs.loading.close()
          })
        this.equipe_check = true
        this.first_time_check = false
      }
    },
    getTeamLeaders () {
      this.$vs.loading()
      this.$http.get('teamLeaders/')
        .then((response) => {
          this.teamLeaders = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },

    getAllCompany () {
      this.$http.get('companies/')
        .then((response) => {
          this.companies = response.data
        })
        .catch(() => {
        })
    },
    getAllManagers () {
      this.$http.get('managerSubcontractors/')
        .then((response) => {
          this.managers = response.data
        })
        .catch(() => {
        })
    }
  },
  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    if (this.user_role === 'managerSubcontractor') {
      this.company = JSON.parse(localStorage.getItem('userInfo')).company.id
      this.role_dis = true
    }
    window.Missions = this
    this.planification_mission_data = {}
    this.getAllMissions()
    this.getAllProjects()
    this.getAllSites()
    this.getAllManagers()
    this.getAllTeams()
    this.getAlltasks()
    this.getAllstaffs()
    this.getAllVehicles()
    this.getAllLiftingEquipments()
    this.getAllriskProfiles()
    this.getTeamLeaders()
    this.getAllCompany()

  },
  mounted () {
    this.isMounted = true
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_mission
    this.mission_data_up = window.Planifications.planification_mission_data
    if (this.mission_data === 1) {
      this.addNewData_mission()     
      //this.activePrompt1 = this.mission_data

    } 

    if (this.mission_data === 2) {
      this.ShowData_mission(this.mission_data_up)
    } 

    if (this.mission_data === 3) {
      this.editData_mission(this.mission_data_up)
    } 

    if (this.mission_data === 4) {
      this.confirmation_show(this.mission_data_up.id, this.mission_data_up.missionConfirmation)
    } 

    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2

    }
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>  